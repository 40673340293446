import React, {Component} from 'react';
import SignupContainer from "../../components/Signup/SignupContainer";
import {httpClient} from "../../utils/http-client";
import {toast} from "react-toastify";
import {Trans, withTranslation} from "react-i18next";


class SignupConfirmation extends Component {

    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this)
        this.handleGoNextStep = this.handleGoNextStep.bind(this)

        this.state = {
            isLoading: false
        }
    }

    handleBack() {
        this.props.history.goBack();
    }

    handleGoNextStep() {
        this.props.history.push({
            pathname: '/signup/company/address',
            state: {}
        })
    }

    handleFinish() {
        window.location.href = '/'
    }

    render() {
        return (
            <>
                <SignupContainer>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div style={{
                            display: "flex",
                            maxWidth: "350px",
                            justifyContent: "",
                            flexDirection: "column",
                        }}>

                            <div style={{
                                fontSize: '14px',
                                marginTop: "50px",
                                fontWeight: "500",
                                color: "#cc3366",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Etapa {{current: this.props.step}} de {{total: this.props.stepsTotal}}
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "20px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans i18nKey={"signupSuccess"}>
                                    Obrigado pelo seu cadastro!
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: "14px",
                                marginTop: "20px",
                                color: "#000066"
                            }}>
                                <Trans i18nKey={"signupSuccessTeamContact"}>
                                    Nosso time analisará seus documentos e retornará para você em breve.
                                </Trans>
                            </div>

                            
                                <button class={"btnDefault"} style={{fontWeight: "300", marginTop: "20px"}} onClick={this.handleFinish}>
                                    <Trans>Voltar</Trans></button>
                        </div>

                    </div>
                </SignupContainer>
            </>
        );

    }
}


export default withTranslation()(SignupConfirmation);
