import React, {Component} from 'react';
import SignupContainer from "../../components/Signup/SignupContainer";
import {Trans, withTranslation} from "react-i18next";


class SignupSuccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    render() {
        return (
            <>
                <SignupContainer>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                        height: "100vh"
                    }}>
                        <div style={{
                            display: "flex",
                            maxWidth: "350px",
                            alignSelf: "center",
                            justifyContent: "",
                            flexDirection: "column",
                            textAlign: "center"
                        }}>

                            <div style={{
                                fontSize: '24px',
                                fontWeight: "bold",
                                marginTop: "20px",
                                color: "#000066",
                                justifySelf: 'start'
                            }}>
                                <Trans>
                                    Cadastro concluído!
                                </Trans>
                            </div>

                            <div style={{
                                fontSize: "14px",
                                marginTop: "20px"
                            }}>
                                <Trans i18nKey={"signupSuccess"}>
                                    Obrigado pelo seu cadastro! Nosso time analisará seus documentos e retornará para você em breve.
                                </Trans>
                            </div>

                        </div>
                    </div>
                </SignupContainer>
            </>
        );

    }
}


export default withTranslation()(SignupSuccess);
